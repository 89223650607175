import Methods from './methods'
import AWS from 'aws-sdk'

export default {
  /* * Type: updaload type. Ex: 'notice'
  *    File: file object
  * */
  upload(s3Info, file) {
    return Promise.resolve()
      .then(() => this.readFileAllBrowsers(file))
      .then(fileContent => {
        AWS.config.update({
          region      : s3Info.region,
          credentials : new AWS.Credentials(s3Info.credentials.AccessKeyId, s3Info.credentials.SecretAccessKey, s3Info.credentials.SessionToken)
        })
        const s3 = new AWS.S3({signatureVersion : 'v4'})

        const params = {
          'Bucket'       : s3Info.bucket,
          'Key'          : s3Info.prefix_key + '/' + file.name, // File name you want to save as in S3
          'Body'         : fileContent,
          'Content-Type' : file.type
        }

        return s3.upload(params).promise()
      })
  },
  getUploadCredentials(type) {
    const params = {
      type
    }
    return Methods.apiExcute('public/get-aws-credentials', 'en', params)
  },
  readFileAllBrowsers(file) {
    console.log('readFileAllBrowsers', file)
    return new Promise((resolve, reject) => {
      if (file) {
        const reader = new FileReader()
        reader.readAsArrayBuffer(file)
        reader.onload = function(evt) {
          resolve(evt.target.result)
        }
        reader.onerror = function(evt) {
          reject(evt)
        }
      } else {
        console.log('readFileAllBrowser123s', file)
        reject({})
      }
    })
  },
  getContentType(filename) {
    const mimes = this.fileMimeTypeMapping()
    const extentions = filename.split('.').pop()
    let content_type = null
    if (mimes.has(extentions)) {
      content_type = mimes.get(extentions)
    }
    return content_type
  },
  fileMimeTypeMapping() {
    const MIMETYPE_MAPPING = new Map()
    MIMETYPE_MAPPING.set('txt', 'text/plain')
    MIMETYPE_MAPPING.set('3gp', 'video/3gpp')
    MIMETYPE_MAPPING.set('7z', 'application/x-7z-compressed')
    MIMETYPE_MAPPING.set('accdb', 'application/msaccess')
    MIMETYPE_MAPPING.set('ai', 'application/illustrator')
    MIMETYPE_MAPPING.set('apk', 'application/vnd.android.package-archive')
    MIMETYPE_MAPPING.set('arw', 'image/x-dcraw')
    MIMETYPE_MAPPING.set('avi', 'video/x-msvideo')
    MIMETYPE_MAPPING.set('bash', 'text/x-shellscript')
    MIMETYPE_MAPPING.set('bat', 'application/x-msdos-program')
    MIMETYPE_MAPPING.set('blend', 'application/x-blender')
    MIMETYPE_MAPPING.set('bin', 'application/x-bin')
    MIMETYPE_MAPPING.set('bmp', 'image/bmp')
    MIMETYPE_MAPPING.set('bpg', 'image/bpg')
    MIMETYPE_MAPPING.set('bz2', 'application/x-bzip2')
    MIMETYPE_MAPPING.set('cb7', 'application/x-cbr')
    MIMETYPE_MAPPING.set('cba', 'application/x-cbr')
    MIMETYPE_MAPPING.set('cbr', 'application/x-cbr')
    MIMETYPE_MAPPING.set('cbt', 'application/x-cbr')
    MIMETYPE_MAPPING.set('cbtc', 'application/x-cbr')
    MIMETYPE_MAPPING.set('cbz', 'application/x-cbr')
    MIMETYPE_MAPPING.set('cc', 'text/x-c')
    MIMETYPE_MAPPING.set('cdr', 'application/coreldraw')
    MIMETYPE_MAPPING.set('class', 'application/java')
    MIMETYPE_MAPPING.set('cnf', 'text/plain')
    MIMETYPE_MAPPING.set('conf', 'text/plain')
    MIMETYPE_MAPPING.set('cpp', 'text/x-c++src')
    MIMETYPE_MAPPING.set('cr2', 'image/x-dcraw')
    MIMETYPE_MAPPING.set('css', 'text/css')
    MIMETYPE_MAPPING.set('csv', 'text/csv')
    MIMETYPE_MAPPING.set('cvbdl', 'application/x-cbr')
    MIMETYPE_MAPPING.set('c', 'text/x-c')
    MIMETYPE_MAPPING.set('c++', 'text/x-c++src')
    MIMETYPE_MAPPING.set('dcr', 'image/x-dcraw')
    MIMETYPE_MAPPING.set('deb', 'application/x-deb')
    MIMETYPE_MAPPING.set('dng', 'image/x-dcraw')
    MIMETYPE_MAPPING.set('doc', 'application/msword')
    MIMETYPE_MAPPING.set('docm', 'application/vnd.ms-word.document.macroEnabled.12')
    MIMETYPE_MAPPING.set('docx', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
    MIMETYPE_MAPPING.set('dot', 'application/msword')
    MIMETYPE_MAPPING.set('dotx', 'application/vnd.openxmlformats-officedocument.wordprocessingml.template')
    MIMETYPE_MAPPING.set('dv', 'video/dv')
    MIMETYPE_MAPPING.set('eot', 'application/vnd.ms-fontobject')
    MIMETYPE_MAPPING.set('epub', 'application/epub+zip')
    MIMETYPE_MAPPING.set('eps', 'application/postscript')
    MIMETYPE_MAPPING.set('erf', 'image/x-dcraw')
    MIMETYPE_MAPPING.set('exe', 'application/x-ms-dos-executable')
    MIMETYPE_MAPPING.set('flac', 'audio/flac')
    MIMETYPE_MAPPING.set('flv', 'video/x-flv')
    MIMETYPE_MAPPING.set('gif', 'image/gif')
    MIMETYPE_MAPPING.set('gpx', 'application/gpx+xml')
    MIMETYPE_MAPPING.set('gz', 'application/gzip')
    MIMETYPE_MAPPING.set('gzip', 'application/gzip')
    MIMETYPE_MAPPING.set('h', 'text/x-h')
    MIMETYPE_MAPPING.set('heic', 'image/heic')
    MIMETYPE_MAPPING.set('heif', 'image/heif')
    MIMETYPE_MAPPING.set('hh', 'text/x-h')
    MIMETYPE_MAPPING.set('hpp', 'text/x-h')
    MIMETYPE_MAPPING.set('htaccess', 'text/plain')
    MIMETYPE_MAPPING.set('ical', 'text/calendar')
    MIMETYPE_MAPPING.set('ics', 'text/calendar')
    MIMETYPE_MAPPING.set('iiq', 'image/x-dcraw')
    MIMETYPE_MAPPING.set('impress', 'text/impress')
    MIMETYPE_MAPPING.set('java', 'text/x-java-source')
    MIMETYPE_MAPPING.set('jp2', 'image/jp2')
    MIMETYPE_MAPPING.set('jpeg', 'image/jpeg')
    MIMETYPE_MAPPING.set('jpg', 'image/jpeg')
    MIMETYPE_MAPPING.set('jps', 'image/jpeg')
    MIMETYPE_MAPPING.set('k25', 'image/x-dcraw')
    MIMETYPE_MAPPING.set('kdc', 'image/x-dcraw')
    MIMETYPE_MAPPING.set('key', 'application/x-iwork-keynote-sffkey')
    MIMETYPE_MAPPING.set('keynote', 'application/x-iwork-keynote-sffkey')
    MIMETYPE_MAPPING.set('kml', 'application/vnd.google-earth.kml+xml')
    MIMETYPE_MAPPING.set('kmz', 'application/vnd.google-earth.kmz')
    MIMETYPE_MAPPING.set('kra', 'application/x-krita')
    MIMETYPE_MAPPING.set('ldif', 'text/x-ldif')
    MIMETYPE_MAPPING.set('love', 'application/x-love-game')
    MIMETYPE_MAPPING.set('lwp', 'application/vnd.lotus-wordpro')
    MIMETYPE_MAPPING.set('m2t', 'video/mp2t')
    MIMETYPE_MAPPING.set('m3u', 'audio/mpegurl')
    MIMETYPE_MAPPING.set('m3u8', 'audio/mpegurl')
    MIMETYPE_MAPPING.set('m4a', 'audio/mp4')
    MIMETYPE_MAPPING.set('m4b', 'audio/m4b')
    MIMETYPE_MAPPING.set('m4v', 'video/mp4')
    MIMETYPE_MAPPING.set('markdown', 'text/markdown')
    MIMETYPE_MAPPING.set('mdown', 'text/markdown')
    MIMETYPE_MAPPING.set('md', 'text/markdown')
    MIMETYPE_MAPPING.set('mdb', 'application/msaccess')
    MIMETYPE_MAPPING.set('mdwn', 'text/markdown')
    MIMETYPE_MAPPING.set('mkd', 'text/markdown')
    MIMETYPE_MAPPING.set('mef', 'image/x-dcraw')
    MIMETYPE_MAPPING.set('mkv', 'video/x-matroska')
    MIMETYPE_MAPPING.set('mobi', 'application/x-mobipocket-ebook')
    MIMETYPE_MAPPING.set('mov', 'video/quicktime')
    MIMETYPE_MAPPING.set('mp3', 'audio/mpeg')
    MIMETYPE_MAPPING.set('mp4', 'video/mp4')
    MIMETYPE_MAPPING.set('mpeg', 'video/mpeg')
    MIMETYPE_MAPPING.set('mpg', 'video/mpeg')
    MIMETYPE_MAPPING.set('mpo', 'image/jpeg')
    MIMETYPE_MAPPING.set('msi', 'application/x-msi')
    MIMETYPE_MAPPING.set('mts', 'video/MP2T')
    MIMETYPE_MAPPING.set('mt2s', 'video/MP2T')
    MIMETYPE_MAPPING.set('nef', 'image/x-dcraw')
    MIMETYPE_MAPPING.set('numbers', 'application/x-iwork-numbers-sffnumbers')
    MIMETYPE_MAPPING.set('odf', 'application/vnd.oasis.opendocument.formula')
    MIMETYPE_MAPPING.set('odg', 'application/vnd.oasis.opendocument.graphics')
    MIMETYPE_MAPPING.set('odp', 'application/vnd.oasis.opendocument.presentation')
    MIMETYPE_MAPPING.set('ods', 'application/vnd.oasis.opendocument.spreadsheet')
    MIMETYPE_MAPPING.set('odt', 'application/vnd.oasis.opendocument.text')
    MIMETYPE_MAPPING.set('oga', 'audio/ogg')
    MIMETYPE_MAPPING.set('ogg', 'audio/ogg')
    MIMETYPE_MAPPING.set('ogv', 'video/ogg')
    MIMETYPE_MAPPING.set('one', 'application/msonenote')
    MIMETYPE_MAPPING.set('opus', 'audio/ogg')
    MIMETYPE_MAPPING.set('orf', 'image/x-dcraw')
    MIMETYPE_MAPPING.set('otf', 'application/font-sfnt')
    MIMETYPE_MAPPING.set('pages', 'application/x-iwork-pages-sffpages')
    MIMETYPE_MAPPING.set('pdf', 'application/pdf')
    MIMETYPE_MAPPING.set('pfb', 'application/x-font')
    MIMETYPE_MAPPING.set('pef', 'image/x-dcraw')
    MIMETYPE_MAPPING.set('php', 'application/x-php')
    MIMETYPE_MAPPING.set('pl', 'application/x-perl')
    MIMETYPE_MAPPING.set('pls', 'audio/x-scpls')
    MIMETYPE_MAPPING.set('png', 'image/png')
    MIMETYPE_MAPPING.set('pot', 'application/vnd.ms-powerpoint')
    MIMETYPE_MAPPING.set('potm', 'application/vnd.ms-powerpoint.template.macroEnabled.12')
    MIMETYPE_MAPPING.set('potx', 'application/vnd.openxmlformats-officedocument.presentationml.template')
    MIMETYPE_MAPPING.set('ppa', 'application/vnd.ms-powerpoint')
    MIMETYPE_MAPPING.set('ppam', 'application/vnd.ms-powerpoint.addin.macroEnabled.12')
    MIMETYPE_MAPPING.set('pps', 'application/vnd.ms-powerpoint')
    MIMETYPE_MAPPING.set('ppsm', 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12')
    MIMETYPE_MAPPING.set('ppsx', 'application/vnd.openxmlformats-officedocument.presentationml.slideshow')
    MIMETYPE_MAPPING.set('ppt', 'application/vnd.ms-powerpoint')
    MIMETYPE_MAPPING.set('pptm', 'application/vnd.ms-powerpoint.presentation.macroEnabled.12')
    MIMETYPE_MAPPING.set('pptx', 'application/vnd.openxmlformats-officedocument.presentationml.presentation')
    MIMETYPE_MAPPING.set('ps', 'application/postscript')
    MIMETYPE_MAPPING.set('psd', 'application/x-photoshop')
    MIMETYPE_MAPPING.set('py', 'text/x-python')
    MIMETYPE_MAPPING.set('raf', 'image/x-dcraw')
    MIMETYPE_MAPPING.set('rar', 'application/x-rar-compressed')
    MIMETYPE_MAPPING.set('reveal', 'text/reveal')
    MIMETYPE_MAPPING.set('rss', 'application/rss+xml')
    MIMETYPE_MAPPING.set('rtf', 'application/rtf')
    MIMETYPE_MAPPING.set('rw2', 'image/x-dcraw')
    MIMETYPE_MAPPING.set('schema', 'text/plain')
    MIMETYPE_MAPPING.set('sgf', 'application/sgf')
    MIMETYPE_MAPPING.set('sh-lib', 'text/x-shellscript')
    MIMETYPE_MAPPING.set('sh', 'text/x-shellscript')
    MIMETYPE_MAPPING.set('srf', 'image/x-dcraw')
    MIMETYPE_MAPPING.set('sr2', 'image/x-dcraw')
    MIMETYPE_MAPPING.set('tar', 'application/x-tar')
    MIMETYPE_MAPPING.set('tar.bz2', 'application/x-bzip2')
    MIMETYPE_MAPPING.set('tar.gz', 'application/x-compressed')
    MIMETYPE_MAPPING.set('tbz2', 'application/x-bzip2')
    MIMETYPE_MAPPING.set('tcx', 'application/vnd.garmin.tcx+xml')
    MIMETYPE_MAPPING.set('tex', 'application/x-tex')
    MIMETYPE_MAPPING.set('tgz', 'application/x-compressed')
    MIMETYPE_MAPPING.set('tiff', 'image/tiff')
    MIMETYPE_MAPPING.set('tif', 'image/tiff')
    MIMETYPE_MAPPING.set('ttf', 'application/font-sfnt')
    MIMETYPE_MAPPING.set('vcard', 'text/vcard')
    MIMETYPE_MAPPING.set('vcf', 'text/vcard')
    MIMETYPE_MAPPING.set('vob', 'video/dvd')
    MIMETYPE_MAPPING.set('vsd', 'application/vnd.visio')
    MIMETYPE_MAPPING.set('vsdm', 'application/vnd.ms-visio.drawing.macroEnabled.12')
    MIMETYPE_MAPPING.set('vsdx', 'application/vnd.ms-visio.drawing')
    MIMETYPE_MAPPING.set('vssm', 'application/vnd.ms-visio.stencil.macroEnabled.12')
    MIMETYPE_MAPPING.set('vssx', 'application/vnd.ms-visio.stencil')
    MIMETYPE_MAPPING.set('vstm', 'application/vnd.ms-visio.template.macroEnabled.12')
    MIMETYPE_MAPPING.set('vstx', 'application/vnd.ms-visio.template')
    MIMETYPE_MAPPING.set('wav', 'audio/wav')
    MIMETYPE_MAPPING.set('webm', 'video/webm')
    MIMETYPE_MAPPING.set('woff', 'application/font-woff')
    MIMETYPE_MAPPING.set('wpd', 'application/vnd.wordperfect')
    MIMETYPE_MAPPING.set('wmv', 'video/x-ms-wmv')
    MIMETYPE_MAPPING.set('xcf', 'application/x-gimp')
    MIMETYPE_MAPPING.set('xla', 'application/vnd.ms-excel')
    MIMETYPE_MAPPING.set('xlam', 'application/vnd.ms-excel.addin.macroEnabled.12')
    MIMETYPE_MAPPING.set('xls', 'application/vnd.ms-excel')
    MIMETYPE_MAPPING.set('xlsb', 'application/vnd.ms-excel.sheet.binary.macroEnabled.12')
    MIMETYPE_MAPPING.set('xlsm', 'application/vnd.ms-excel.sheet.macroEnabled.12')
    MIMETYPE_MAPPING.set('xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    MIMETYPE_MAPPING.set('xlt', 'application/vnd.ms-excel')
    MIMETYPE_MAPPING.set('xltm', 'application/vnd.ms-excel.template.macroEnabled.12')
    MIMETYPE_MAPPING.set('xltx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.template')
    MIMETYPE_MAPPING.set('xrf', 'image/x-dcraw')
    MIMETYPE_MAPPING.set('yaml', 'application/yaml')
    MIMETYPE_MAPPING.set('yml', 'application/yaml')
    MIMETYPE_MAPPING.set('zip', 'application/zip')
    MIMETYPE_MAPPING.set('url', 'application/internet-shortcut')
    MIMETYPE_MAPPING.set('webloc', 'application/internet-shortcut')
    MIMETYPE_MAPPING.set('js', 'application/javascript')
    MIMETYPE_MAPPING.set('json', 'application/json')
    MIMETYPE_MAPPING.set('fb2', 'application/x-fictionbook+xml')
    MIMETYPE_MAPPING.set('html', 'text/html')
    MIMETYPE_MAPPING.set('htm', 'text/html')
    MIMETYPE_MAPPING.set('m', 'text/x-matlab')
    MIMETYPE_MAPPING.set('svg', 'image/svg+xml')
    MIMETYPE_MAPPING.set('swf', 'application/octet-stream')
    MIMETYPE_MAPPING.set('xml', 'application/xml')

    return MIMETYPE_MAPPING
  }
}
