import Methods from '@/api/methods'
import UploadFile from '@/api/uploadFile'
export default {
  data() {
    return {
      confirmPassword : null,
      errors          : {},

      // Password viewing
      passwordType        : 'password',
      passwordConfirmType : 'password',
      currentPasswordType : 'password'
    }
  },
  mounted() {
    Methods.addExternalScirpts(this.scripts)
  },
  methods : {
    searchPostCode() {
      const params = {
        postCode : this.parent.registerData.postCode
      }
      this.$store.state.loading = true
      Methods.apiExcute('search-post-code', this.$i18n.locale, params).then(data => {
        this.$store.state.loading = false
        this.parent.registerData.state = data.address1
        this.parent.registerData.city = data.address2
        this.parent.registerData.address = data.address3
      })
        .catch(error => {
          this.$store.state.loading = false
          this.errors = Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    validate() {
      if (this.parent.mode === 'RegisterMember') {
        this.parent.registerData.nameCardFilePath = this.parent.nameCardFiles.map(file => file.key)
        this.parent.registerData.antiqueFilePath = this.parent.antiqueFiles.map(file => file.key)
        this.parent.registerData.exportFilePath = this.parent.exportFiles.map(file => file.key)
      }
      const params = {
        registerData : this.parent.registerData,
        validateFlag : true
      }
      this.errors = {}
      this.checkInputFormat()
      this.$store.state.loading = true
      this.request(params).then(data => {
        if (Object.keys(this.errors).length === 0) {
          this.uploadFiles()
            .then(() => {
              this.$store.state.loading = false
              this.parent.editFlag = false
              window.scrollTo({
                top      : 0,
                left     : 0,
                behavior : 'smooth'
              })
            })
            .catch(error => {
              this.$store.state.loading = false
              console.log('アップロード失敗', error)
            })
        } else {
          this.$store.state.loading = false
        }
      })
        .catch(error => {
          console.log('error', error)
          this.$store.state.loading = false
          this.errors = Object.assign(Methods.parseHtmlResponseError(this.$router, error), this.errors)
        })
    },
    checkInputFormat() {
      let tags = [
        'branchOffice',
        'companyName',
        'industry',
        'emailUserName',
        'representativeDirector'
      ]

      if (this.parent.mode === 'RegisterMember') {
        tags = [
          'state',
          'city',
          'address',
          'branchOffice',
          'companyName',
          'industry',
          'emailUserName',
          'representativeDirector'
        ]
      }

      const pattern = new RegExp('^[0-9a-zA-Z -/:-@\[-~]+$')
      const jpPattern = new RegExp('^([^\x01-\x7E]|[0-9a-zA-Z -/:-@\[-~])+$')
      this.errors = {}
      for (const tag of tags) {
        if (this.parent.registerData[tag]) {
          if (this.parent.selectedCountry && this.parent.selectedCountry.value1 === 'JP' && !jpPattern.test(this.parent.registerData[tag])) {
            this.errors[tag] = this.$t('MEMBER_REQUEST_TEXT_FORMAT_ERROR')
          } else if (this.parent.selectedCountry.value1 !== 'JP' && !pattern.test(this.parent.registerData[tag])) {
            this.errors[tag] = this.$t('MEMBER_REQUEST_TEXT_FORMAT_ERROR')
          }
        }
      }
      if (this.extraValidate) {
        this.extraValidate()
      }
    },
    addExportFile(file) {
      if (file.target.files.length > 0) {
        if (this.checkFileSize(file.target.files[0])) {
          this.errors = Object.assign({}, this.errors, {exportFilePath : this.checkFileSize(file.target.files[0])})
        } else {
          this.parent.exportFiles.push(file.target.files[0])
          this.parent.registerData.exportFilePath.push(file.target.files[0].name)
        }
      }
    },
    addAntiqueFile(file) {
      if (file.target.files.length > 0) {
        if (this.checkFileSize(file.target.files[0])) {
          this.errors = Object.assign({}, this.errors, {antiqueFilePath : this.checkFileSize(file.target.files[0])})
        } else {
          this.parent.antiqueFiles.push(file.target.files[0])
          this.parent.registerData.antiqueFilePath.push(file.target.files[0].name)
        }
      }
    },
    addNameCardFile(file) {
      if (file.target.files.length > 0) {
        if (this.checkFileSize(file.target.files[0])) {
          this.errors = Object.assign({}, this.errors, {nameCardFilePath : this.checkFileSize(file.target.files[0])})
        } else {
          this.parent.nameCardFiles.push(file.target.files[0])
          this.parent.registerData.nameCardFilePath.push(file.target.files[0].name)
        }
      }
    },
    checkFileSize(file) {
      if (file && file.size) {
        // 2022.02.22: 制限が5MBにする
        if (file.size > 5 * 1024 * 1024) {
          return this.$t('MY_PAGE_UPDATE_FILE_SIZE_LIMIT_ERROR_MESSAGE')
        }
      }
      return null
    },
    uploadFiles() {
      return Promise.resolve()
        .then(() => {
          if (this.parent.exportFiles.filter(file => !file.key).length > 0 || this.parent.antiqueFiles.filter(file => !file.key).length > 0 || this.parent.nameCardFiles.filter(file => !file.key).length > 0) {
            return UploadFile.getUploadCredentials('member-request')
          } else {
            return Promise.resolve({})
          }
        })
        .then(credentials => {
          return Promise.all([
            Promise.all(this.parent.exportFiles.filter(file => !file.key).map((file, index) => {
              const s3access = credentials
                ? Object.assign({}, credentials, {
                  prefix_key : credentials.prefix_key + '/exportFiles/' + index
                })
                : {}
              return UploadFile.upload(s3access, file)
                .then(result => {
                  file.key = result.Key
                  return Promise.resolve(result.Key)
                })
            })),
            Promise.all(this.parent.antiqueFiles.filter(file => !file.key).map((file, index) => {
              const s3access = credentials
                ? Object.assign({}, credentials, {
                  prefix_key : credentials.prefix_key + '/antiqueFiles/' + index
                })
                : {}
              return UploadFile.upload(s3access, file)
                .then(result => {
                  file.key = result.Key
                  return Promise.resolve(result.Key)
                })
            })),
            Promise.all(this.parent.nameCardFiles.filter(file => !file.key).map((file, index) => {
              const s3access = credentials
                ? Object.assign({}, credentials, {
                  prefix_key : credentials.prefix_key + '/nameCardFiles/' + index
                })
                : {}
              return UploadFile.upload(s3access, file)
                .then(result => {
                  file.key = result.Key
                  return Promise.resolve(result.Key)
                })
            }))
          ])
            .then(result => {
              this.parent.registerData.nameCardFilePath = this.parent.nameCardFiles.length > 0 ? this.parent.nameCardFiles.map(file => file.key) : null
              this.parent.registerData.antiqueFilePath = this.parent.antiqueFiles.length > 0 ? this.parent.antiqueFiles.map(file => file.key) : null
              this.parent.registerData.exportFilePath = this.parent.exportFiles.length > 0 ? this.parent.exportFiles.map(file => file.key) : null
            })
        })
    },
    isJapanView() {
      const ret = (this.parent.selectedCountry && this.parent.selectedCountry.value1 === 'JP') || (this.$i18n.locale === 'ja' && (!this.parent.selectedCountry || !this.parent.selectedCountry.value1))
      if (!ret) {
        this.parent.registerData.state = ''
        this.parent.registerData.city = ''
      }
      return ret
    },
    showHidePassword(type = 1) {
      switch (type) {
      case 1:
        this.currentPasswordType = this.currentPasswordType === 'password' ? 'text' : 'password'
        break
      case 2:
        this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
        break
      case 3:
        this.passwordConfirmType = this.passwordConfirmType === 'password' ? 'text' : 'password'
        break
      default:
        break
      }
    }
  },
  watch : {
    'parent.registerData.countryCode'(value) {
      this.parent.selectedCountry = this.parent.constants.find(constant => constant.key_string === 'COUNTRY_CODE' && constant.value1 === value)
      this.parent.antiqueFiles = []
    },
    errors(value) {
      const keys = Object.keys(value)
      if (keys.length > 0 && this.$refs[keys[0]]) {
        this.$refs[keys[0]].focus()
      }
    }
  }
}
